<template>
  <section>
    <title-section
      title="Dashboard"
    />
    <main-dashboard />
  </section>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    MainDashboard: () => import('@/components/Dashboard/MainDashboard.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
